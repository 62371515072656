import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import avatar from "../../../Assets/vectors/users/avatar.svg";
import dot from "../../../Assets/vectors/users/dot.svg";
import edit from "../../../Assets/vectors/users/edit.svg";
import reset from "../../../Assets/vectors/users/reset.svg";
import del from "../../../Assets/vectors/users/del.svg";
import profile from "../../../Assets/vectors/users/profile.svg";
import pp from "../../../Assets/img/users/pp.png";
import search from "../../../Assets/vectors/users/search.svg";
import avatar_user from "../../../Assets/vectors/users/avatar_user.svg";
import cancel from "../../../Assets/vectors/users/cancel.svg";
import validate from "../../../Assets/vectors/users/validate.svg";
import admin_icon from "../../../Assets/vectors/users/admin_icon.svg";
import icon_adm from "../../../Assets/vectors/users/icon_adm.svg";
import eye from "../../../Assets/vectors/users/eye.svg";
import sort_white from "../../../Assets/vectors/users/sort_white.svg";
import az_icon from "../../../Assets/vectors/users/a-z.svg";
import za_icon from "../../../Assets/vectors/users/z-a.svg";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import PopupUpdate from "./PopupUpdate";
import ReactPaginate from "react-paginate";
import Modal from "../../common/Modal";
import SeeProfil from "../SeeProfil/SeeProfil";
import { getUser } from "../../../Redux/slices/user";
import { useQuery } from "react-query";
import config from "../../../Config/config.js"
import Swal from "sweetalert2";

const Users = () => {
  const [allUsers, setAllUsers] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [tmpOptions, setTmpOptions] = useState(false);
  const [newUserToCreate, setNewUserToCreate] = useState(false);
  const [currentKeyOptions, setCurrentKeyOptions] = useState(0);
  const [dataNewUser, setDataNewUser] = useState({});
  const [showEnterPassword, setShowEnterPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [currentUsername, setCurrentUsername] = useState(null);
  const [isAdminSelected, setIsAdminSelected] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [inputUpdatePassword, setInputUpdatePassword] = useState("");
  const [currentUserToUpdate, setCurrentUserToUpdate] = useState(null);
  const [currentDataToDisplay, setCurrentDataToDisplay] = useState(null);
  const [valueTabActive, setValueTabActive] = useState("users");
  const [adminList, setAdminList] = useState(null);
  const [sortAlphabetic, setSortAlphabetic] = useState("default");
  const [pageValue, setPageValue] = useState(0);
  const [isIconAlphabetic, setIsIconAlphabetic] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };
  
  useEffect(() => {
    fetchUsers();
  }, []);

  const ref = useRef();
  let totalValue = 0;

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  //const pageCount = Math.ceil(currentDataToDisplay.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    if (currentDataToDisplay) {
      setPageValue(Math.ceil(currentDataToDisplay.length / usersPerPage));
      //setCurrentDataToDisplay(currentDataToDisplay.slice(0).reverse());
    }
  }, [currentDataToDisplay]);

  useEffect(() => {
    if (currentUserToUpdate) {
      if (currentUserToUpdate.is_admin) {
        setIsAdminSelected(true);
      } else {
        setIsAdminSelected(false);
      }
    }
  }, [currentUserToUpdate]);

  useEffect(() => {
    switch (valueTabActive) {
      case "admin":
        setCurrentDataToDisplay(
          allUsers.filter((user) => user["is_admin"] === true)
        );
        break;
      default:
        setCurrentDataToDisplay(allUsers);
    }
  }, [valueTabActive]);

  useEffect(() => {
    let data = dataNewUser;
    data["is_admin"] = isAdminSelected;
    setDataNewUser(data);
  }, [isAdminSelected]);

  const compare_az = (a, b) => {
    if (a.username.toLowerCase() < b.username.toLowerCase()) {
      return -1;
    }
    if (a.username.toLowerCase() > b.username.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    console.log("in sort useEffect");
    let data = {};
    switch (sortAlphabetic) {
      case "sort-A-Z":
        setCurrentDataToDisplay(
          [...currentDataToDisplay.sort(compare_az)].slice(0).reverse()
        );
        break;
      case "sort-Z-A":
        setCurrentDataToDisplay(
          [...currentDataToDisplay.sort(compare_za)].slice(0).reverse()
        );
        break;
      default:
    }
  }, [sortAlphabetic]);

  useEffect(() => {
    const checkIfClickedOutsideSearch = (e) => {
      if (tmpOptions && ref.current && !ref.current.contains(e.target)) {
        setTmpOptions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideSearch);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideSearch);
    };
  }, [tmpOptions]);

  const fetchUsers = () => {
    const urlUsers = `${routes.routes.back_end.admin.users.users.url}`;
    const methodUsers = `${routes.routes.back_end.admin.users.users.method}`;
    ApiService.fetchApiData(urlUsers, methodUsers, "user")
      .then((result) => {
        //console.log("result : ", result);
        setAllUsers(result);
        setCurrentDataToDisplay(result);
        setAdminList(result.filter((value) => value["is_admin"] == true));
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  const create_user = () => {
    const urlCreate = `${routes.routes.back_end.admin.users.create_user.url}`;
    const methodCreate = `${routes.routes.back_end.admin.users.create_user.method}`;

    let data = dataNewUser;
    data.is_admin = isAdminSelected;

    ApiService.postExpectFormData(urlCreate, methodCreate, data, "user")
      .then((result) => {
        fetchUsers();
        //setAllUsers(result);
        setShowEnterPassword(false);
        setNewUserToCreate(false);
      })
      .catch(() => {
        Swal.fire("Error", `Error in creating user'`, "error");
      });
  };

  const handleCreateUser = () => {
    setCurrentUserToUpdate(null);
    setNewUserToCreate(true);
  };

  const hadndleOptions = (key) => {
    setCurrentKeyOptions(key);
    setTmpOptions(!tmpOptions);
  };

  const handle_input_new_user = (e, field) => {
    //console.log(e.target.value);
    let data = dataNewUser;
    data[field] = e.target.value;
    setDataNewUser(data);
  };

  const deleteUser = (username) => {
    //console.log("usersname in deleteUesr : ", username);
    setCurrentUsername(username);
    setIsDeleteUser(true);
    setTmpOptions(false);
  };

  const fetchUsername = (method) => {
    const urlDelete = `${routes.routes.back_end.admin.users.user_delete.url}`;
    const methodDelete = `${routes.routes.back_end.admin.users.user_delete.method}`;
    let data = {};
    if (method == "PUT" && inputUpdatePassword !== "") {
      data.password = inputUpdatePassword;
    }
    data["username"] = currentUsername;
    console.log(
      "currentUsername : ",
      currentUsername,
      " --- :  method : ",
      method
    );
    console.log("data : ", data);
    ApiService.sendingPutData(urlDelete + currentUsername, method, data, "user")
      .then((result) => {
        //console.log("result : ", result);
        //console.log("in delete or update ");
        setIsDeleteUser(false);
        setIsResetPassword(false);
        fetchUsers();
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const handleResetPassword = (username) => {
    setIsResetPassword(true);
    setCurrentUsername(username);
    setTmpOptions(false);
  };

  const handleEditUser = (user) => {
    setCurrentUserToUpdate(user);
    setTmpOptions(false);
    setNewUserToCreate(true);
    setCurrentUsername(user.username);
    setDataNewUser(user);
  };
  const updateUser = () => {
    const urlUpdate = `${routes.routes.back_end.admin.users.user_delete.url}`;
    let data = {};
    data.email = dataNewUser.email;
    data.phone_number = dataNewUser.phone_number;
    data.lastname = dataNewUser.lastname;
    data.firstname = dataNewUser.firstname;
    data.username = dataNewUser.username;
    data.is_admin = dataNewUser.is_admin;
    data.adress = dataNewUser.adress;
    data.is_admin = isAdminSelected;
    ApiService.postExpectFormData(urlUpdate + currentUsername, "PUT", data, "user")
      .then((result) => {
        getUser(result);
        fetchUsers();
        setNewUserToCreate(false);
        Swal.fire({
          icon: 'success',
          title: 'User has been updated',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const handleTab = (value) => {
    setValueTabActive(value);
  };

  const compare_za = (a, b) => {
    if (a.username.toLowerCase() < b.username.toLowerCase()) {
      return 1;
    }
    if (a.username.toLowerCase() > b.username.toLowerCase()) {
      return -1;
    }
    return 0;
  };

  return (
    <div className="flex flex-fd-c users">
      <div className="flex users_select flex-fd-c flex-js-fs">
        <div className="flex users_select_content">
          <div
            className={`flex users_select_item  ${
              valueTabActive === "users"
                ? "users_select_tab-active"
                : "users_select_tab-inactive"
            }`}
          >
            <span
              className="users_select_item_text"
              onClick={() => handleTab("users")}
            >
              All users
            </span>
            <img className="users_select_item_img" src={dot} alt="dot" />
            <span className="users_select_item_number">
              {allUsers && allUsers.length}
            </span>
          </div>
          <div
            className={`flex users_select_item  ${
              valueTabActive === "admin"
                ? "users_select_tab-active"
                : "users_select_tab-inactive"
            }`}
          >
            <span
              className="users_select_item_text "
              onClick={() => handleTab("admin")}
            >
              Admin
            </span>
            <img className="users_select_item_img" src={dot} alt="dot" />
            <span className="users_select_item_number">
              {adminList && adminList.length}
            </span>
          </div>
        </div>
        <div className="flex users_select_content_down">
          <div className="flex flex-js-fs users_select_content_down_line"></div>
          <div className="flex flex-js-fs users_select_content_down_input">
            <input
              className="users_select_content_down_input_input"
              placeholder="search in this board"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <img
              className="users_select_content_down_input_img"
              src={search}
              alt="search"
            />
          </div>
          <div className="flex flex-js-fs users_select_content_down_line2"></div>
          <div
            className="flex users_select_content_down_btn"
            onClick={() => handleCreateUser()}
          >
            <button className="users_select_content_down_btn_content">
              create user
            </button>
          </div>
        </div>
      </div>
      <div className="users_header">
        <div className="flex users_header_row">
          {newUserToCreate ? (
            <div className="users_header_row_username">
              <span>username</span>
              <span className="users_header_row_username_admin">
                Administrator
              </span>
            </div>
          ) : (
            <>
              <div
                className="flex users_header_row_user"
                onMouseEnter={(e) => setIsIconAlphabetic(true)}
                onMouseLeave={(e) => setIsIconAlphabetic(false)}
              >
                <span>user</span>
                {sortAlphabetic == "default" && isIconAlphabetic && (
                  <img
                    className="users_header_row_user_icon"
                    src={sort_white}
                    alt="sort_white"
                    onClick={() => setSortAlphabetic("sort-A-Z")}
                  />
                )}

                {sortAlphabetic == "sort-A-Z" && (
                  <img
                    className="users_header_row_user_icon"
                    src={az_icon}
                    alt="az_white"
                    onClick={() => setSortAlphabetic("sort-Z-A")}
                  />
                )}
                {sortAlphabetic == "sort-Z-A" && (
                  <img
                    className="users_header_row_user_icon"
                    src={za_icon}
                    alt="za_white"
                    onClick={() => setSortAlphabetic("default")}
                  />
                )}
              </div>
            </>
          )}

          <div className="users_header_row_lastname">Lastname</div>
          <div className="users_header_row_firstname">Firstname</div>
          <div className="users_header_row_mail">Mail</div>
          <div className="users_header_row_phone">Phone</div>
          <div className="users_header_row_adress">Adress</div>
          <div className="users_header_row_status">Status</div>
          <div className="users_header_row_birthdate">Birth Date</div>
          <div className="users_header_row_option">Option</div>
        </div>
      </div>
      <div className="flex flex-fd-c users_list">
        {/** new user to create */}
        {newUserToCreate && (
          <div className="users_list_create-user">
            <div className="users_list_create-user_row flex  flex-js-fs">
              <div className="users_list_create-user_row_avatar">
                <img className="" src={pp} />
              </div>
              <div className="flex users_list_create-user_row_username">
                <input
                  className="users_list_create-user_row_username_input all-input-create-user"
                  onChange={(e) => handle_input_new_user(e, "username")}
                  placeholder={
                    currentUserToUpdate && currentUserToUpdate.username
                      ? currentUserToUpdate.username
                      : "Enter username"
                  }
                />
                <div className="flex users_list_create-user_row_username_admin  all-input-create-user">
                  <div
                    className={`users_list_create-user_row_username_admin_select  ${
                      isAdminSelected
                        ? "users_list_create-user_row_username_admin_selected"
                        : ""
                    }`}
                    onClick={() => setIsAdminSelected(!isAdminSelected)}
                  ></div>
                  <img src={admin_icon} alt="admin_icon" />
                  <span>Admin</span>
                </div>
              </div>
              <div className="users_list_create-user_row_last-name">
                <input
                  className="users_list_create-user_row_last-name_input all-input-create-user"
                  onChange={(e) => handle_input_new_user(e, "lastname")}
                  placeholder={
                    currentUserToUpdate && currentUserToUpdate.last_name
                      ? currentUserToUpdate.last_name
                      : "Enter lastname"
                  }
                />
              </div>
              <div className="users_list_create-user_row_last-name">
                <input
                  className="users_list_create-user_row_last-name_input all-input-create-user"
                  onChange={(e) => handle_input_new_user(e, "firstname")}
                  placeholder={
                    currentUserToUpdate && currentUserToUpdate.first_name
                      ? currentUserToUpdate.first_name
                      : "Enter firstname"
                  }
                />
              </div>
              <div className="users_list_create-user_row_mail">
                <input
                  className="users_list_create-user_row_mail_input all-input-create-user"
                  onChange={(e) => handle_input_new_user(e, "email")}
                  placeholder={
                    currentUserToUpdate && currentUserToUpdate.email
                      ? currentUserToUpdate.email
                      : "Enter Email"
                  }
                />
              </div>
              <div className="users_list_create-user_row_phone">
                <input
                  className="users_list_create-user_row_phone_input all-input-create-user"
                  onChange={(e) => handle_input_new_user(e, "phone_number")}
                  placeholder={
                    currentUserToUpdate && currentUserToUpdate.phone_number
                      ? currentUserToUpdate.phone_number
                      : "Enter phone number"
                  }
                />
              </div>
              <div className="users_list_create-user_row_phone">
                <input
                  className="users_list_create-user_row_phone_input all-input-create-user"
                  onChange={(e) => handle_input_new_user(e, "adress")}
                  placeholder={
                    currentUserToUpdate && currentUserToUpdate.adress
                      ? currentUserToUpdate.adress
                      : "Enter Adress"
                  }
                />
              </div>
              <div className="flex users_list_create-user_row_actions">
                <img
                  className="users_list_create-user_row_actions_img"
                  src={cancel}
                  alt="cancel"
                  onClick={() => setNewUserToCreate(false)}
                />
                {currentUserToUpdate ? (
                  <img
                    className="users_list_create-user_row_actions_img"
                    src={validate}
                    alt="validate"
                    onClick={() => updateUser()}
                  />
                ) : (
                  <img
                    className="users_list_create-user_row_actions_img"
                    src={validate}
                    alt="validate"
                    onClick={() => setShowEnterPassword(!showEnterPassword)}
                  />
                )}
              </div>
            </div>
            <div className="users_list_line"></div>
          </div>
        )}
        {currentDataToDisplay &&
          currentDataToDisplay
            .filter((value) => value.username.includes(searchValue))
            .slice(0)
            .reverse()
            .slice(pagesVisited, pagesVisited + usersPerPage)
            .map((value, key) => {
              return (
                <React.Fragment key={key}>
                  <div className="flex users_list_row flex-js-fs">
                    <div className="users_list_row_avatar">
                    {value.avatar && isValidUrl(value.avatar) ? (
                      <>
                        <img
                          src={value.avatar}
                          className="profile_user_avatar_img"
                        />
                      </>
                    ) : (
                      <img
                        src={pp}
                        className=""
                      />
                    )}
                    </div>
                    <div className="flex users_list_row_user">
                      {value.username}
                      {value.is_admin && (
                        <img
                          src={icon_adm}
                          alt="icon_adm"
                          className="users_list_row_user_img"
                        />
                      )}
                    </div>
                    <div className="users_list_row_last_name users_list_row_text">
                      {value.last_name}
                    </div>
                    <div className="users_list_row_first_name users_list_row_text">
                      {value.first_name}
                    </div>
                    <div className="users_list_row_mail users_list_row_text">
                      {value.email}
                    </div>
                    <div className="users_list_row_phone users_list_row_text">
                      {value.phone_number}
                    </div>
                    <div className="users_list_row_phone users_list_row_text">
                      {value.adresse}
                    </div>
                    <div className="users_list_row_phone users_list_row_text">
                      {value.status_verified !== null
                        ? value.status_verified
                        : "not verified"}
                    </div>
                    <div className="users_list_row_birthdate users_list_row_text">
                      {value.birth_date}
                    </div>
                    <div
                      className="users_list_row_option"
                      onClick={() => hadndleOptions(key)}
                    >
                      ...
                      {key == currentKeyOptions && tmpOptions && (
                        <div className="popup" ref={ref}>
                          <div className="popup_content">
                            <div className="popup_content_actions">Actions</div>
                            <div
                              className="flex popup_content_item"
                              onClick={() => handleEditUser(value)}
                            >
                              <img
                                className="flex popup_content_item_img"
                                src={edit}
                              />
                              <span className="popup_content_item_text">
                                Edit information
                              </span>
                            </div>
                            <div
                              className="flex popup_content_item"
                              onClick={() =>
                                handleResetPassword(value.username)
                              }
                            >
                              <img
                                className="flex popup_content_item_img"
                                src={reset}
                              />
                              <span className="popup_content_item_text">
                                Reset password
                              </span>
                            </div>
                            <div
                              className="flex popup_content_item"
                              onClick={() => handleOpenModal(value)}
                            >
                              <img
                                className="flex popup_content_item_img"
                                src={profile}
                              />
                              <span className="popup_content_item_text">
                                See profile
                              </span>
                            </div>

                            <div
                              className="flex popup_content_item"
                              onClick={() => deleteUser(value.username)}
                            >
                              <img
                                className="flex popup_content_item_img"
                                src={del}
                              />
                              <span className="popup_content_item_text popup_content_item_delete">
                                Delete user
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="users_list_line"></div>
                  {showEnterPassword && (
                    <div className="popup-enter-password popup-enter-password-create">
                      <div className="popup-enter-password_content">
                        <div className="flex flex-jc-c popup-enter-password_content_header">
                          <img
                            className="popup-enter-password_content_header_img"
                            src={avatar_user}
                            alt="avatar_user"
                          />
                          <span>newuser</span>
                        </div>
                        <div className="flex flex-fd-c flex-jc-c popup-enter-password_content_assign">
                          <span>Assign a default password</span>
                        </div>
                        <div className="popup-enter-password_content_input">
                          {showPassword ? (
                            <input
                              type="text"
                              id="password_input"
                              className="popup-enter-password_content_input_input"
                              placeholder="Enter your password"
                              onChange={(e) =>
                                handle_input_new_user(e, "password")
                              }
                            />
                          ) : (
                            <input
                              type="password"
                              id="password_input"
                              className="popup-enter-password_content_input_input"
                              placeholder="Enter your password"
                              onChange={(e) =>
                                handle_input_new_user(e, "password")
                              }
                            />
                          )}

                          <img
                            className="popup-enter-password_content_input_eye"
                            src={eye}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </div>
                        <div className="flex flex-jc-c popup-enter-password_content_want">
                          <span>Do you want to create this user</span>
                        </div>
                        <div className="flex popup-enter-password_content_btn">
                          <button
                            className="popup-enter-password_content_btn_cancel"
                            onClick={() => setShowEnterPassword(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="popup-enter-password_content_btn_create"
                            onClick={() => create_user()}
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {isDeleteUser && (
                    <div className="popup-delete-user">
                      <div className="popup-delete-user_content">
                        <div className="flex flex-jc-c popup-delete-user_content_header">
                          <img
                            className="popup-delete-user_content_header_img"
                            src={pp}
                            alt="pp_user"
                          />
                          <span>{currentUsername}</span>
                        </div>
                        <div className="flex flex-jc-c popup-delete-user_content_want">
                          <span>Are you sure to delete this user</span>
                        </div>
                        <div className="flex popup-delete-user_content_btn">
                          <button
                            className="popup-enter-password_content_btn_cancel"
                            onClick={() => setIsDeleteUser(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="popup-delete-user_content_btn_delete"
                            onClick={() => fetchUsername("DELETE")}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {isResetPassword && (
                    <div className="popup-enter-password popup-enter-password-update">
                      <div className="popup-enter-password_content">
                        <div className="flex flex-jc-c popup-enter-password_content_header">
                          <img
                            className="popup-enter-password_content_header_img-update"
                            src={pp}
                            alt="ppp"
                          />
                          <span>{currentUsername}</span>
                        </div>
                        <div className="flex flex-fd-c flex-jc-c popup-enter-password_content_assign">
                          <span>Assign a new password</span>
                        </div>
                        <div className="popup-enter-password_content_input">
                          {showPassword ? (
                            <input
                              type="text"
                              id="password_inp"
                              className="popup-enter-password_content_input_update"
                              placeholder="Enter your password"
                              onChange={(e) =>
                                setInputUpdatePassword(e.target.value)
                              }
                            />
                          ) : (
                            <input
                              type="password"
                              id="password_in"
                              className="popup-enter-password_content_input_update"
                              placeholder="Enter your password"
                              onChange={(e) =>
                                setInputUpdatePassword(e.target.value)
                              }
                            />
                          )}

                          <img
                            className="popup-enter-password_content_input_eye"
                            src={eye}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </div>
                        <div className="flex popup-enter-password_content_btn">
                          <button
                            className="popup-enter-password_content_btn_cancel"
                            onClick={() => setIsResetPassword(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="popup-enter-password_content_btn_create"
                            onClick={() => fetchUsername("PUT")}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        <Modal isOpen={isOpen} title="See Profile" onClose={onClose}>
          <SeeProfil userProfil={selectedUser} />
        </Modal>
        {currentDataToDisplay && (
          <>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageValue}
              //pagecount={Math.ceil(currentDataToDisplay.length / usersPerPage)}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </>
        )}
      </div>
      {console.log("currenDataToDisplay end : ", currentDataToDisplay)}
    </div>
  );
};

export default Users;
